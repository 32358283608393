import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { useIsSubmitting } from "remix-validated-form";
import { Link } from "@remix-run/react";
import { Loader2 } from "lucide-react";
import { cn } from "@/lib/shadcn-utils";


const buttonVariants = cva(
  "inline-flex gap-3 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-30 dark:ring-offset-gray-950 dark:focus-visible:ring-gray-300",
  {
    variants: {
      variant: {
        default: "border border-primary-500 bg-primary-500 text-white hover:bg-primary-600 dark:bg-primary-600 dark:text-white hover:opacity-80",
        destructive: "border border-red-500 bg-red-500 text-white hover:opacity-80 dark:bg-red-600 dark:border-red-600 dark:text-gray-50",
        "destructive-outline": "border bg-transparent text-red-500 hover:opacity-80 dark:border-red-500 hover:opacity-70",
        outline: "border border-gray-200 bg-transparent hover:opacity-70 text-gray-900 dark:text-gray-50 dark:border-white/20",
        "outline-dark": "border border-gray-500 bg-transparent hover:opacity-70 text-gray-200",
        "icon-only": "text-primary-500",
        "icon-only-primary": ""
        // secondary: "bg-gray-100 text-gray-900 hover:bg-gray-100/80 dark:bg-gray-800 dark:text-gray-50 dark:hover:bg-gray-800/80",
        // ghost: "hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-gray-50",
        // link: "text-gray-900 underline-offset-4 hover:underline dark:text-gray-50"
      },
      size: {
        default: "px-3 py-1.5",
        sm: "rounded-md px-2 py-1",
        lg: "rounded-md px-6 py-4"
        // icon: "h-10 w-10"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  submit?: boolean;
  to?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      to,
      submit,
      ...props
    }, ref) => {
    const Comp = asChild ? Slot : "button";
    let isSubmitting;
    // this works and is good because the hooks are still called in the same order because submit doesn't change once mounted
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (submit) isSubmitting = useIsSubmitting();
    const button = <Comp className={cn(buttonVariants({ variant, size, className }))}
                         ref={ref}
                         {...props}
                         disabled={isSubmitting || props.disabled}
    >
      {isSubmitting ? <div className="animate-spin"><Loader2 /></div> : props.children}
    </Comp>;
    if (to) {
      return <Link to={to}>
        {button}
      </Link>;
    }
    return button;
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
